.insights-page {
  padding: 20px;
  background: linear-gradient(to bottom, #121212, #35144f, #5a2b68, #873d84);
  color: #ffffff;
  text-align: center;
  min-height: 100vh;
  font-family: "Arial", sans-serif;
  position: relative;
  z-index: 1;
  overflow: auto;
  padding: 20px 20px 50px;
}

.insights-header {
  font-size: 2.8rem;
  margin-bottom: 30px;
  color: #ff79c6;
  text-align: center;

  /* Responsive header font size */
  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 480px) {
    font-size: 1.8rem;
  }
}

.insights-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  max-width: 1000px;
  margin: 0 auto;
  margin-bottom: 30px; 

  /* Adjust grid gaps and layout for smaller screens */
  @media (max-width: 768px) {
    gap: 15px;
  }

  @media (max-width: 480px) {
    gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}

.insight-item {
  background-color: rgba(75, 0, 130, 0.8);
  border: 2px solid rgba(255, 255, 255, 0.2);
  padding: 20px;
  border-radius: 12px;
  font-size: 1.3rem;
  line-height: 1.5;
  color: #f0f0f0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  transition: all 0.3s ease-in-out;

  /* Responsive font size for smaller screens */
  @media (max-width: 768px) {
    font-size: 1.1rem;
    padding: 15px;
  }

  @media (max-width: 480px) {
    font-size: 1rem;
    padding: 10px;
  }
}

.insight-item:hover {
  transform: scale(1.05);
  background-color: rgba(238, 130, 238, 0.9);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.6);
  color: #ffffff;
}

.insight-item:active {
  transform: scale(0.98);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}
